@tailwind base;
@tailwind components;
@tailwind utilities;

/* header {
  background-image: 
} */

* {
  scroll-behavior: smooth;
}

.card-zoom {
  @apply relative items-center justify-center overflow-hidden shadow-xl;
}

.card-zoom * {
  @apply z-10;
}

.card-zoom-image,
.card-blur-image {
  @apply absolute w-full min-h-full transition-all duration-500 ease-in-out transform bg-center bg-cover;
}

.card-zoom:hover .card-zoom-image {
  @apply scale-150;
}

.card-blur {
  @apply relative items-center justify-center;
}
.card-blur * {
  @apply z-10;
}

/* .card-blur:hover .card-blur-image {
  @apply filter blur-sm transition-all duration-500 ease-in-out transform;
} */

.title-font {
  @apply lg:text-9xl md:text-8xl sm:text-6xl text-4xl font-rationale;
}

.section-font {
  @apply lg:text-3xl md:text-3xl sm:text-2xl text-2xl px-5 font-roboto;
  text-shadow: 2px 2px 0px black;
  /* text-shadow: "-3px 3px 0 #000,3px 3px 0 #000,3px -3px 0 #000,-3px -3px 0 #000, 5px 5px 5px rgba(0,0,0,.7)";
  box-shadow: "inset 0px 159px 38px -106px black"; */
}

.header-animation {
  animation: header-swap;
  animation-duration: 2500ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  opacity: 1;
}

@keyframes header-swap {
  50% {
    filter: blur(10px);
  }
}

.leaflet-container {
  width: 700px;
  height: 600px;
  overflow: hidden;
}

.map__container {
  height: 500px;
  overflow: hidden;
  background: blue;
}
